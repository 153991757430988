.input-form .col-form-label{
    font-weight: normal;
}

.form-error {
    color: white;
    font-style: italic;
    background-color: red;
}

.input-form .form-error {
    /* width: 100%; */
    text-align: right;
    display: inline;
    float: right;
    padding: 0px 2px;
}

.form-error {
    /* width: 100%; */
    text-align: right;
    display: inline;
    float: right;
    padding: 0px 2px;
}