body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
/* Hide material-table toolbar*/
/*
.MuiToolbar-root {
  display: none!important;
}
*/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.pic{
  width:150px;
  cursor: pointer;
}

.react-pdf__Page__canvas{
  width:100%!important;
  height: 100%!important;
}

.top-media-toolbar{
  text-align: left;
  margin: 10px 0px;
}

.media-container {
  max-width: 1024px;
  margin: auto;
}

.hidden{
  display: none!important;
  background-color: red!important;
}
.CursorPointer{
    cursor: pointer;
}

.CursorPointer:hover{
    background-color: lightgray;
}


div#menu{
    background-color: springgreen;
    text-align: center!important;
}

div#menu a.nav-link{
    color: #115da9!important;
    cursor: pointer;
}

div#menu a.nav-link:hover{
    color: #115da9!important;
    font-weight: bolder;
}

.CursorPointer{
    cursor: pointer;
}

.CursorPointer:hover{
    background-color: lightgray;
}

.modal-backdrop {
    background-color: rgba(1,1,1,0.5)!important;/* Avoid black background */
}

.booklet-container{
    overflow-y: auto;
    border: lightgray solid 1px;
    border-radius: 7px;
    max-height: 500px;
    cursor: text;
}





.input-form .col-form-label{
    font-weight: normal;
}

.form-error {
    color: white;
    font-style: italic;
    background-color: red;
}

.input-form .form-error {
    /* width: 100%; */
    text-align: right;
    display: inline;
    float: right;
    padding: 0px 2px;
}

.form-error {
    /* width: 100%; */
    text-align: right;
    display: inline;
    float: right;
    padding: 0px 2px;
}
#upload-picture-label{
    cursor: pointer;
}

#upload-picture-label input{
    opacity: 0;
    width: 0px;
    height: 0px;
}

div.show-image {
    position: relative;
    float:left;
    margin:5px;
}
div.show-image:hover img{
    opacity:0.5;
}
div.show-image:hover input {
    display: block;
}
div.show-image input {
    position:absolute;
    display:none;
}
div.show-image input.update {
    top:0;
    left:0;
}
div.show-image input.delete {
    top:0;
    left:79%;
}
