.modal-backdrop {
    background-color: rgba(1,1,1,0.5)!important;/* Avoid black background */
}

.booklet-container{
    overflow-y: auto;
    border: lightgray solid 1px;
    border-radius: 7px;
    max-height: 500px;
    cursor: text;
}